import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Card } from 'antd'

import AuditorTable from './components/AuditorTable'
import { auditor as auditorApi } from '@rap/api'

const Browse = () => {
  const [isLoading, setLoading] = useState(true)
  const [auditorList, setAuditorList] = useState([])

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    auditorApi.getVerifiedAuditors().then((res) => {
      setAuditorList(res.data.auditors)
      setLoading(false)
    })
  }, [])

  return (
    <Card className="cardContent" bordered={false}>
      <AuditorTable
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isLoading={isLoading}
        auditorList={auditorList}
      />
    </Card>
  )
}

export default Browse
