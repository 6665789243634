import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

import MainLayout from '../layout/MainLayout'

// Main website
import Home from '../pages/home'
import Browse from '../pages/browse'

import { AboutUs, Terms, ContactUs, Privacy } from '../pages/info/InfoMarkdown'
import FAQ from '../pages/info/FAQ'

import { NotFound } from '../pages/exception/NotFound'

import AuditorProfile from '../pages/auditor/Profile'
import ClaimProfile from '../pages/auditor/ClaimProfile'
import AddAuditorForm from '../pages/browse/components/AuditorTable/AddAuditor'
import DiscussionForm from '../pages/Discussion/discussionForm'
import Login from '../pages/login'
import Signup from '../pages/signup'
import VerifyEmail from '../pages/verifyEmail'
import ForgotPassword from '../pages/forgotPassword'
import FeedbackForm from '../pages/contact'
import UserProfile from '../pages/user/Profile'
import AccountSetting from '../pages/account/Setting'
import ResetPassword from '../pages/resetPassword'
import Admin from "../pages/admin";

import CommentVerification from "../pages/admin/comment_verification";
import Tnc from "../pages/contact/termsAndCondition";
import Pp from "../pages/contact/PrivacyPolicy"
import AuditorApproval from '../pages/browse/components/AuditorTable/AddAduitor-AdminApproval'
// Forum
// import ForumHome from './forum/home/Home'
// import Category from './forum/category/Category'
// import Topic from './forum/topic/Topic'

// import Login from './account/Login'
// import Auth from './auth/Auth'


const Main = () => (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<Home />} />
      <Route path="home" element={<Navigate to="/" replace />} />
      <Route path="browse" element={<Browse />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route path="verify-email" element={<VerifyEmail />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="feedback-form" element={<FeedbackForm />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="add-auditor" element={<AddAuditorForm />} />
      <Route path="terms-and-conditions" element={<Tnc />} />
      <Route path="privacy-policy" element={<Pp />} />
      <Route path="discussion-form" element={<DiscussionForm />} />
      <Route path="auditor-approval" element={<AuditorApproval />} />
      <Route path="info">
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="contactus" element={<FeedbackForm />} />
      </Route>
      <Route path="auditor">
        <Route path="claim-profile" element={<ClaimProfile />} />
        <Route path=":aid" element={<AuditorProfile />} />
      </Route>
      <Route path="user">
        <Route index element={<UserProfile />} />
        <Route path=":username" element={<UserProfile />} />
      </Route>
      <Route path="account">
        <Route path="setting" element={<AccountSetting />} />
      </Route>
      <Route path="admin" element={<Admin />} />

      {/* <Route path="/signup-auditor" element={AuditorRegister} /> */}
      {/* <Route path="/support" element={Support} /> */}
      <Route path="*" element={<NotFound />} />
    </Route>
    {/* <Route path="forum" element={<MainLayout />}>
              <Route index element={<ForumHome />} />
              <Route path="cat">
                  <Route path=":cat" element={<Category />} />
              </Route>
              <Route path="topic">
                  <Route path=":tid" element={<Topic />} />
              </Route>
          </Route>
          <Route path="*" element={<NotFound />} /> */}
  </Routes>
)

export default Main
