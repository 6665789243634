import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Image, Menu, Drawer, Button } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import './style.css'

import {
  restoreLoginStatus,
  logout,
  selectLoginStatus,
  selectUserInfo,
} from '../../../redux/userSlice'
import { TITLE } from '../../../config'
import NavbarUser from './components/UserSection/NavbarUser'
import logo_light from '../../../static/logo_light.svg'

interface IPathTitle {
  [key: string]: string
}

const PathTitle: IPathTitle = {
  home: 'Find, Review & Rate an Audit Partner',
  browse: 'Browse',
  forum: 'Forum',
  faq: 'Frequently Asked Questions',
  aboutus: 'About Us',
  terms: 'Terms of Use',
  privacy: 'Privacy Policy',
  notfound: 'Page Not Found',
}

const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname.slice(1).split('/')[0] || 'home'
  let title = `${path} | ${TITLE}`
  if (PathTitle[path]) {
    title = `${PathTitle[path]} | ${TITLE}`
  }

  const dispatch = useDispatch()
  const loginStatus = useSelector(selectLoginStatus)
  const userInfo = useSelector(selectUserInfo)

  const [drawerVisible, setDrawerVisible] = useState(false)

  const MenuItems = (userInfo && userInfo.permission_group.includes('admin'))
    ? [
      { key: 'home', label: 'Home' },
      { key: 'browse', label: 'Browse' },
      { key: 'info/faq', label: 'FAQ' },
      { key: 'discussion-form', label: 'Discussion' },
      { key: 'admin', label: 'Admin' },
    ]
    : [
      { key: 'home', label: 'Home' },
      { key: 'browse', label: 'Browse' },
      { key: 'info/faq', label: 'FAQ' },
      //discussion-form is not used in the current version
      { key: 'discussion-form', label: 'Discussion' },
    ]

  const handleLogout = () => {
    dispatch(logout())
  }

  useEffect(() => {
    dispatch(restoreLoginStatus())
  }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-stretch gap-4">
        <Link to="/" className="self-center">
          <div className="hide-on-mobile">
            <Image
              height={48}
              width={48}
              src={logo_light}
              alt="logo"
              preview={false}
              className="hide-on-mobile"
            />
            <span className="text-lg font-semibold text-black">RateAuditPartner</span>
          </div>
          <span className="hide-not-in-mobile">RateAuditPartner</span>
        </Link>
        <div className="flex-auto hidden md:block">
          <Menu
            mode="horizontal"
            selectedKeys={[path]}
            onClick={({ key }) => {
              navigate(`/${key}`)
            }}
            items={MenuItems}
          />
        </div>
        <div className="md:hidden flex items-center">
          <Button
            icon={<MenuOutlined />}
            onClick={() => setDrawerVisible(true)}
          />
        </div>
        <NavbarUser
          isLogin={loginStatus === 'success'}
          userInfo={userInfo}
          onLogout={handleLogout}
        />
      </div>
      <Drawer
        title="Menu"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <Menu
          mode="vertical"
          selectedKeys={[path]}
          onClick={({ key }) => {
            navigate(`/${key}`)
            setDrawerVisible(false)
          }}
          items={MenuItems}
        />
        <div className="mt-4">
          {loginStatus === 'success' ? (
            <>
              <Button block onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button block onClick={() => navigate('/login')}>
                Login
              </Button>
              <Button block type="primary" className="mt-2" onClick={() => navigate('/signup')}>
                Sign Up
              </Button>
            </>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default Header
