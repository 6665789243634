import React, { useEffect, useState } from 'react'

import { auditor as auditorApi, rate as rateApi } from '@rap/api'
import SearchSimple from './components/SearchSimple'
import HomePopular from './components/HomePopular'
import HomeRate from './components/HomeRate'
import { useNavigate } from 'react-router-dom'
import {selectUserInfo} from "../../redux/userSlice";
import {useSelector} from "react-redux";

interface rateData {
    average: number,
    communication: number,
    comment: string,
    commenter: string,
    created_at: string,
    updated_at: string,
    _id: string,
    knowledge: number,
    quality: number,
    team: number,
    value: number,
    author: {
        username: string,
        permission_group: string[],
    }
    auditor: {
        _id: string,
        first_name: string,
        last_name: string,
        firm_issuing_state: string,
        firm_issuing_country: string,
        firm_issuing_city: string,
        firm_name: string,
    }
}

const Home = () => {
    const [popularAuditors, setPopularAuditors] = useState([])
    const [recentRates, setRecentRates] = useState([])
    const queryParams = new URLSearchParams(window.location.search)
    const param1 = queryParams.get('page')
    const param2 = queryParams.get('token')
    const navigate = useNavigate()
    const userInfo = useSelector(selectUserInfo)


    useEffect(() => {
        auditorApi.getPopularAuditors().then((res) => {
          setPopularAuditors(res.data.recent_popular)
        })
        rateApi.getRecentVerifiedRates().then((res) => {
          setRecentRates(res.data.recent_rates)
        })
        if (param1) {
            if (param1 === '1') {
                navigate('/reset-password/?token=' + param2)
            } else {
                navigate(param1)
            }
        }
    }, [])



  return (
    <>
      <div className="bg-white shadow-md mb-6 p-6">
        <h2 className="font-bold text-xl">RateAuditor.org</h2>
        <p>
          Welcome! Rateauditor.org is the site where you can provide independent reviews and
          recommendations for audit partners.
        </p>
      </div>
      <SearchSimple />
      <div className="md:grid grid-cols-2 gap-6">
        <HomePopular popularAuditors={popularAuditors} />
        <HomeRate recentRates={recentRates} />
      </div>
    </>
  )
}

export default Home
