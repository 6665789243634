import React, { useEffect, useState } from 'react'
import { Typography, Row, Col, Collapse, Card } from 'antd'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import faqList from './faqList.json'

const { Title, Paragraph } = Typography

function FAQ() {
  return (
    <Card className="cardContent" bordered={false}>
      <Row justify="center">
        <Title>Frequently Asked Questions</Title>
      </Row>
      {/* <Row justify="center">
                <Col xs={24} md={20}>
                    <Input.Search
                        placeholder="Your Question"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={text => {console.log(text)}}
                        disabled
                    />
                </Col>
            </Row> */}
      <Row justify="center">
        <Col xs={24} md={20}>
          <Collapse defaultActiveKey={['0']}>
            {faqList.faq.map(function (element, index) {
              return (
                <Collapse.Panel header={element.q} key={index}>
                  <Paragraph style={{ whiteSpace: 'pre-line' }}>
                    <ReactMarkdown>{element.a}</ReactMarkdown>
                  </Paragraph>
                </Collapse.Panel>
              )
            })}
          </Collapse>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} md={20}>
          <Paragraph>
            If you have more questions, please click <Link to="/support">contact us</Link>. We
            cannot guarantee a response, but we will read every question and comment.
          </Paragraph>
        </Col>
      </Row>
    </Card>
  )
}

export default FAQ
