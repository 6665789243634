import React, { useState, useEffect } from 'react'
import {
  StarTwoTone,
  LikeOutlined,
  MessageFilled,
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons'
import { List, Rate, Row, Col, Tag, Divider, Button, Popconfirm, notification } from 'antd'
import dayjs from '../../../../utils/dayjs'
import { rate as rateApi } from '@rap/api'
import { Link } from 'react-router-dom'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import styles from './index.module.sass'
import { RateForm } from '../../../../auditor/components'

interface IRateListProps {
  rates: rateauditor.api.rate.IRateInfo[]
  handleDeleteRate: (id: string) => void
}

const RateList = ({ rates, handleDeleteRate }: IRateListProps) => {
  const [editingId, setEditingId] = useState<string | null>(null)
  const [auditorNames, setAuditorNames] = useState<string[] | null>([])
  const [auditorIds, setAuditorIds] = useState<string[] | null>([])
  const [stats, setStats] = useState<{
    totalRatings: number
    verifiedRatings: number
    pendingRatings: number
    averageRating: number
  }>({
    totalRatings: 0,
    verifiedRatings: 0,
    pendingRatings: 0,
    averageRating: 0,
  })

  useEffect(() => {
    const fetchAuditorNames = async () => {
      const auditorData = await Promise.all(
        rates.map(rate =>
          rateApi
            .getRateById(rate._id)
            .then(
              response => ({
                name: response.data.rate.auditor.first_name + ' ' + response.data.rate.auditor.last_name,
                id: response.data.rate.auditor._id,
              }),
              error => ({ name: 'Unknown', id: 'Unknown' }) // Handle errors or missing data gracefully
            )
        )
      )
      const names = auditorData.map(data => data.name)
      const ids = auditorData.map(data => data.id)
      setAuditorNames(names)
      setAuditorIds(ids)

      // Calculate statistics
      const totalRatings = rates.length
      const verifiedRatings = rates.filter(rate => rate.status === 'verified').length
      const pendingRatings = rates.filter(rate => rate.status === 'pending').length
      const totalAverage =
        rates.reduce((acc, rate) => acc + rate.average, 0) / totalRatings || 0

      setStats({
        totalRatings,
        verifiedRatings,
        pendingRatings,
        averageRating: totalAverage,
      })
    }
    fetchAuditorNames()
  }, [rates])

  const handleEditClick = (id: string) => {
    // Toggle edit mode for the row with the given ID
    setEditingId(prev => (prev === id ? null : id))
  }

  const handleSubmitRate = (form: rateauditor.api.rate.IRateBasicInfo, resetForm: () => void) => {
    if (editingId){
      rateApi.getRateById(editingId).then((res) => {
        console.log('editingRate', res.data)
        const rate = {
          knowledge: form.knowledge || 0,
          communication: form.communication || 0,
          team: form.team || 0,
          value: form.value || 0,
          quality: form.quality || 0,
          comment: form.comment || '',
        }
        rateApi.postRate(res.data.rate.auditor._id, rate).then((res) => {
          notification.success({
            message: 'Rate Submitted',
            description: 'Your rating will be displayed after review',
          })
          rateApi.deleteRate(editingId).then((res) => {
            if (res.data.status === 'success') {
              setEditingId(null)
              location.reload()
            } else {
              notification.warning(res.data.error.message)
            }
          })
        })
      })
    }
  }

  const IconText = ({ icon, text }: { icon: JSX.Element; text: string }) => (
    <span>
      {icon} {text}
    </span>
  )

  const isMobile = window.innerWidth <= 768;

  const verificationPercentage = (stats.verifiedRatings / stats.totalRatings) * 100 || 0
  let verificationMessage = ''
  if (verificationPercentage < 33) {
    verificationMessage = 'The verification process is just getting started.'
  } else if (verificationPercentage <= 50) {
    verificationMessage = 'Verification is progressing well.'
  } else {
    verificationMessage = 'Great job! Most ratings have been verified.'
  }

  const RateStatus = ({ status }: { status: rateauditor.api.rate.status }) => {
    switch (status) {
      case 'verified':
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Verified
          </Tag>
        )
      case 'pending':
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            Queued for verification
          </Tag>
        )
      case 'blocked':
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Reviewer rejected
          </Tag>
        )
      default:
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            {status || 'working'}
          </Tag>
        )
    }
  }

  return (
    <Row gutter={16}>
      <Col xs={24} lg={16}>
        <List
          size="large"
          className={styles.articleList}
          rowKey="_id"
          itemLayout="vertical"
          dataSource={rates || []}
          renderItem={(item, index) => (
            <List.Item
              key={item._id}
              // actions={[
              //     <IconText key="star" icon={<StarTwoTone />} text={item.star} />,
              //     <IconText key="like" icon={<LikeOutlined />} text={item.like} />,
              //     <IconText key="message" icon={<MessageFilled />} text={item.message} />,
              // ]}
            >
              {editingId === item._id ? (
                <div>
                  <RateForm isSubmitting={false} handleRateSubmit={handleSubmitRate} FillValues={item} />
                  <Popconfirm
                    placement="topRight"
                    title="You will lose any edits made to this rate. Are you sure you want to cancel editing this rate?"
                    onConfirm={() => {
                      handleEditClick(item._id)
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link">Cancel Edit</Button>
                  </Popconfirm>
                </div>
              ) : (
                <>
                  <List.Item.Meta
                    title={
                      <Row>
                        <Col>
                          <Rate disabled allowHalf value={parseFloat(item.average.toFixed(1))} />
                        </Col>
                        <Col span={8} style={{ textAlign: 'center', verticalAlign: 'bottom' }}>
                          Auditor: <Link to={auditorIds ? `/auditor/${auditorIds[index]}` : '/'}>{auditorNames ? auditorNames[index] : 'No auditor'}</Link>
                        </Col>
                      </Row>
                    }
                    description={
                      <div className={styles.description}>
                        <Row justify="space-between" className={styles.rateDetail}>
                          <Col xs={24} sm={4}>{`Team: ${item.team.toFixed(1)}`}</Col>
                          <Col xs={24} sm={4}>{`Value: ${item.value.toFixed(1)}`}</Col>
                          <Col xs={24} sm={5}>{`Knowledge: ${item.knowledge.toFixed(1)}`}</Col>
                          <Col xs={24} sm={6}>{`Communication: ${item.communication.toFixed(1)}`}</Col>
                          <Col xs={24} sm={4}>{`Quality: ${item.quality.toFixed(1)}`}</Col>
                        </Row>
                        <p>{item.comment}</p>
                        <div className={styles.extra}>
                          <div className={styles.createdAt}>
                            {dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')}
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <Divider style={{ margin: '8px 0' }} dashed />
                  <Row justify="space-between">
                    <Col>
                      {item.status && (
                        <span>
                          Status: <RateStatus status={item.status} />
                        </span>
                      )}
                    </Col>
                    <Col>
                      {item.status !== 'verified' && (
                        <Popconfirm
                          placement="topRight"
                          title="Editing the rate will put it in the queue for verification. Are you sure you want to edit this rate?"
                          onConfirm={() => {
                            handleEditClick(item._id)
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">Edit</Button>
                        </Popconfirm>
                      )}
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure you want delete this rate?"
                        onConfirm={() => {
                          handleDeleteRate(item._id)
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">Delete</Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </>
              )}
            </List.Item>
          )}
        />
      </Col>

      <Col xs={24} lg={8}>
        {!isMobile && <div className={styles.dashedDivider}></div>}
        <div className={styles.ratingStats}>
          <h3 className={styles.statsHeading}>Rating Statistics:</h3>
          <div className={styles.statsContainer}>
            <p className={styles.statItem}>Total Ratings:<span>{stats.totalRatings}</span></p>
            <p className={styles.statItem}>Verified Ratings:<span>{stats.verifiedRatings}</span></p>
            <p className={styles.statItem}>Pending Ratings:<span>{stats.pendingRatings}</span></p>
            <p className={styles.statItem}>Average Rating:<span>{stats.averageRating.toFixed(1)}</span></p>
            <br></br>
            <br></br>
            <h3 className={styles.statsHeading}>Verification Progress:</h3>
            <div className={styles.circularMeter}>
            
              <CircularProgressbar
                value={stats.verifiedRatings / stats.totalRatings * 100 || 0}
                text={`${(stats.verifiedRatings / stats.totalRatings * 100).toFixed(1)}%`}
                styles={buildStyles({
                  pathColor: '#5cdbd3',
                  textColor: '#5cdbd3',
                })}
              />
            </div>
            <br></br>
            <p>{verificationMessage}</p>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default RateList
