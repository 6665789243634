import React, { useMemo, useEffect, useState } from 'react';
import { HomeOutlined, ContactsOutlined, ClusterOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.sass';
import { selectUserInfo } from '../../../../redux/userSlice';
import { useSelector } from 'react-redux';

interface IAuditorSummaryProps {
  loading: boolean;
  currentAuditor: rateauditor.api.auditor.IAuditorInfo | undefined;
  rates?: rateauditor.api.rate.IRateInfo[];
  title?: React.ReactNode;
  allowClaim?: boolean;
}

const AuditorSummary = (props: IAuditorSummaryProps) => {
  const { loading, currentAuditor, rates, title, allowClaim } = props;
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [claimed, setClaimed] = useState<boolean>(false);

  useEffect(() => {
    setLoggedIn(!!userInfo);
    setClaimed(currentAuditor?.profile_claim !== 'none');
  }, [currentAuditor]);

  const overall = useMemo(() => {
    const overall = {
      communication: 0,
      knowledge: 0,
      quality: 0,
      team: 0,
      value: 0,
      average: 0,
      count: 0,
    };
    rates?.forEach((rate) => {
      overall.communication += rate.communication;
      overall.knowledge += rate.knowledge;
      overall.quality += rate.quality;
      overall.team += rate.team;
      overall.value += rate.value;
      overall.average += rate.average;
      overall.count += 1;
    });
    if (overall.count !== 0) {
      const overallString = {
        communication: (overall.communication / overall.count).toFixed(1),
        knowledge: (overall.knowledge / overall.count).toFixed(1),
        quality: (overall.quality / overall.count).toFixed(1),
        team: (overall.team / overall.count).toFixed(1),
        value: (overall.value / overall.count).toFixed(1),
        average: (overall.average / overall.count).toFixed(1),
      };
      return overallString;
    }
    return null;
  }, [rates]);

  return (
      <Card
          bordered={false}
          style={{
            marginBottom: 24,
            width: '100%',
          }}
          title={title}
          loading={loading}
      >
        {!loading && currentAuditor ? (
            <div>
              <div className={styles.avatarHolder}>
                <div className={styles.name}>{`${currentAuditor.first_name} ${currentAuditor.last_name}`}</div>
                {/* <div>{currentAuditor?.signature}</div> */}
              </div>
              <div className={styles.detail}>
                <p>
                  <ContactsOutlined
                      style={{
                        marginRight: 8,
                      }}
                  />
                  {/* {currentAuditor.title} */}
                  {'Auditor'}
                </p>
                <p>
                  <ClusterOutlined
                      style={{
                        marginRight: 8,
                      }}
                  />
                  {currentAuditor.firm_name}
                </p>
                <p>
                  <HomeOutlined
                      style={{
                        marginRight: 8,
                      }}
                  />
                  {currentAuditor.firm_issuing_city ? `${currentAuditor.firm_issuing_city} / ` : ''}
                  {currentAuditor.firm_issuing_state ? `${currentAuditor.firm_issuing_state} / ` : ''}
                  {currentAuditor.firm_issuing_country || ''}
                </p>
              </div>
              {loggedIn && allowClaim && !claimed && (
                  <div className="flex justify-center mt-4">
                    <Button
                        onClick={() => {
                          navigate(`/auditor/claim-profile?auditor=${currentAuditor._id}`);
                        }}
                    >
                      Claim this profile
                    </Button>
                  </div>
              )}
              {!loggedIn && allowClaim && !claimed && (
                  <div className="flex justify-center mt-4">
                    <Button
                        onClick={() => {
                          navigate(`/login`);
                        }}
                    >
                      Login to claim this profile
                    </Button>
                  </div>
              )}
              {overall && (
                  <>
                    <Divider dashed />
                    <div className={styles.rateSummary}>
                      <div className={styles.title}>
                        <div>Overall</div>
                        <div className={`${styles.rate} ${styles.overall}`}>{overall.average}</div>
                      </div>
                      <div>
                        <Row gutter={36}>
                          <Col className={styles.label} flex="4 1">
                            Knowledge:
                          </Col>
                          <Col className={styles.rate} flex="3 1">
                            {overall.knowledge}
                          </Col>
                        </Row>
                        <Row gutter={36}>
                          <Col className={styles.label} flex="4 1">
                            Communication:
                          </Col>
                          <Col className={styles.rate} flex="3 1">
                            {overall.communication}
                          </Col>
                        </Row>
                        <Row gutter={36}>
                          <Col className={styles.label} flex="4 1">
                            Team:
                          </Col>
                          <Col className={styles.rate} flex="3 1">
                            {overall.team}
                          </Col>
                        </Row>
                        <Row gutter={36}>
                          <Col className={styles.label} flex="4 1">
                            Value:
                          </Col>
                          <Col className={styles.rate} flex="3 1">
                            {overall.value}
                          </Col>
                        </Row>
                        <Row gutter={36}>
                          <Col className={styles.label} flex="4 1">
                            Quality:
                          </Col>
                          <Col className={styles.rate} flex="3 1">
                            {overall.quality}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
              )}
            </div>
        ) : (
            <div>Profile does not exist!</div>
        )}
      </Card>
  );
};

export default AuditorSummary;