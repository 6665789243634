import React, { useState } from 'react';
import { sendFeedback } from "@rap/api/es/user";
import {Modal} from 'antd';

interface FormData {
  name: string;
  email: string;
  message: string;
}

const FeedbackForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: ''
  });

  const [isHovered, setIsHovered] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const countDown = () => {
    let secondsToGo = 5;
    const instance = modal.success({
      title: 'Your feedback has been submitted!',
      content: `This alert will be close in ${secondsToGo} seconds.`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: `This alert will close after ${secondsToGo} seconds.`,
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendFeedback(formData).then(r => {
      console.log('data sent')
      setFormData({
      name: '',
      email: '',
      message: ''
    });
    countDown();
    })

  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.info}>
        <h2>Contact Us</h2>
        <p>
          We value your feedback and inquiries. Whether you have a question, suggestion, or need assistance, our team is here to help. Please feel free hesitate to reach out to us via email.
        </p>
        <h3>Get in Touch</h3>
        <p><strong>Email:</strong> <a href="mailto:support@rateauditor.org" style={styles.link}>support@rateauditor.org</a></p>
        <h3>Why Contact Us?</h3>
        <ul style={styles.ul}>
          <li><strong>Support:</strong> For any technical issues or questions about using our platform.</li>
          <li><strong>Feedback:</strong> We appreciate your thoughts on how we can improve our services.</li>
          <li><strong>Inquiries:</strong> For any general inquiries or partnership opportunities.</li>
        </ul>
        <p>We strive to respond to all emails within 24-48 hours. Thank you for connecting with us! Thank you for being a part of the RateAuditor community!</p>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label htmlFor="name" style={styles.label}>Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder='Enter your name...'
            value={formData.name}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="email" style={styles.label}>Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder='Enter your email...'
            value={formData.email}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="message" style={styles.label}>Message:</label>
          <textarea
            id="message"
            name="message"
            placeholder='Enter your message...'
            value={formData.message}
            onChange={handleChange}
            required
            style={{ ...styles.input, ...styles.textarea }}
          ></textarea>
        </div>
        <button
          type="submit"
          style={isHovered ? { ...styles.button, ...styles.buttonHover } : styles.button}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Submit
        </button>
        {contextHolder}
      </form>
    </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  info: {
    marginBottom: '20px',
    textAlign: 'left' as const,
    padding: '20px',
    // backgroundColor: '#f1f1f1',
    borderRadius: '8px',
    // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
  ul: {
    paddingLeft: '20px',
  },
  form: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    padding: '20px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box' as const,
  },
  textarea: {
    height: '150px',
    resize: 'none' as const,
  },
  button: {
    display: 'block',
    margin: '0 auto',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
};

export default FeedbackForm;